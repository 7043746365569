import { Component, EventEmitter, Output, computed } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, SharedStore } from '@shared/services';
import { DropdownOption } from '@shared/types';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  @Output() opened = new EventEmitter<boolean>();

  public sidebarOpen = true;
  public isMenuVisible = false;
  public user = this.sharedStore.user.get();
  public form = new FormGroup({ organization_id: new FormControl(0) });
  public authorizedRoutes$ = computed(() => {
    return this.filterRoutesByAuthorized(this.availableRoutes, this.sharedStore.authorizedRoutes.get());
  });
  public availableRoutes = [
    // { label: this.tr.instant('navbar_home'), icon: 'icon_dashboard', link: '/dashboard', routeId: 'home' },
    { label: this.tr.instant('navbar_facilities'), icon: 'icon_locations', link: '/facilities', routeId: 'facilities' },
    {
      label: this.tr.instant('navbar_statistics'),
      icon: 'icon_statistics',
      expanded: false,
      routeId: 'statistics',
      items: [
        { label: this.tr.instant('navbar_summary'), link: '/statistics/summary', routeId: 'summary' },
        { label: this.tr.instant('navbar_insights'), link: '/statistics/insights', routeId: 'insights' },
      ],
    },
    { label: this.tr.instant('navbar_projects'), icon: 'icon_projects', link: '/projects', routeId: 'projects' },
    { label: this.tr.instant('navbar_devices'), icon: 'icon_devices', link: '/devices', routeId: 'devices' },
    {
      label: this.tr.instant('navbar_system'),
      icon: 'icon_system',
      expanded: false,
      routeId: 'system',
      items: [
        { label: this.tr.instant('navbar_users'), link: '/system/users', routeId: 'users' },
        { label: this.tr.instant('navbar_jobs'), link: '/system/jobs', routeId: 'jobs' },
        { label: this.tr.instant('navbar_integrations'), link: '/system/integrations', routeId: 'integrations' },
        {
          label: this.tr.instant('navbar_integration_mappings'),
          link: '/system/integration-mappings',
          routeId: 'integration-mappings',
        },
        { label: this.tr.instant('navbar_activities'), link: '/system/activities', routeId: 'activity-mapping' },
        { label: this.tr.instant('navbar_timeModels'), link: '/system/time-models', routeId: 'system' },
        { label: this.tr.instant('navbar_associations'), link: '/system/associations', routeId: 'associations' },
        {
          label: this.tr.instant('navbar_default_device_configurations'),
          link: '/system/default-device-configurations',
          routeId: 'system',
        },
        { label: this.tr.instant('navbar_licenses'), link: '/system/licenses', routeId: 'system' },
        {
          label: this.tr.instant('navbar_administration'),
          expanded: true,
          routeId: 'SystemAdmin',
          items: [
            {
              label: this.tr.instant('navbar_organizations'),
              link: '/system/admin/organizations',
              routeId: 'organizations',
            },
            { label: this.tr.instant('navbar_roles'), link: '/system/admin/roles', routeId: 'roles' },
            {
              label: this.tr.instant('navbar_license_packages'),
              link: '/system/admin/license-packages',
              routeId: 'licenses',
            },
            {
              label: this.tr.instant('navbar_integrations'),
              link: '/system/admin/integrations',
              routeId: 'admin_integrations',
            },
            {
              label: this.tr.instant('navbar_masterActivities'),
              link: '/system/admin/master-activities',
              routeId: 'standard-activity',
            },
          ],
        },
      ],
    },
  ];
  public bottomNavRoutes = [
    { label: this.tr.instant('navbar_support'), icon: 'icon_support', link: '/support' },
    { label: this.tr.instant('navbar_myProfile'), icon: 'icon_profile', link: '/my-profile' },
  ];
  public organizationList = computed(() => {
    if (!this.user.isSystemAdmin) return [{ name: this.user.organization_name, value: `${this.user.organization_id}` }];

    return this.sharedStore.organizations
      .get()
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((organization) => ({ name: organization.name, value: `${organization.id}` }));
  });

  public currentOrganizationName = computed<string>(() => {
    const organizations = this.sharedStore.organizations.get();
    const orgId = this.sharedStore.organizationId();
    return organizations.find((org) => org.id === orgId)?.name ?? this.tr.instant('navbar_no_organization_selected');
  });

  private pageLoaded = false;

  constructor(
    private sharedStore: SharedStore,
    private authService: AuthService,
    private router: Router,
    private tr: TranslateService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.pageLoaded) this.toggleMenu();
        this.pageLoaded = true;
      }
    });
  }

  public setOrganizationFormControl(field: string, option: DropdownOption | null) {
    this.form.get(field)?.setValue(option ? Number(option.value) : 0);
    this.form.get(field)?.markAsDirty();
  }

  public switchOrganization() {
    this.sharedStore.organizationId.set(Number(this.form.controls['organization_id']?.value ?? 0));
  }

  public onOrganizationIconClick() {
    this.toggleSidebar();
  }

  public toggleSidebar() {
    this.sidebarOpen = !this.sidebarOpen;
    this.opened.emit(this.sidebarOpen);
  }

  public toggleMenu() {
    this.isMenuVisible = !this.isMenuVisible;
  }

  public signOut() {
    this.authService.logout();
  }

  private filterRoutesByAuthorized(routes: any[], authorizedRoutes: string[]) {
    return routes.filter((route) => {
      const isAuthorized = authorizedRoutes.includes(route.routeId) || authorizedRoutes.includes(route.routeId + '_ro');
      if (route.items) {
        route.items = this.filterRoutesByAuthorized(route.items, authorizedRoutes);
      }
      return isAuthorized || (route.items && route.items.length > 0);
    });
  }
}
